import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Box, Checkbox, Grid, Typography } from "@material-ui/core";
import SignatureCanvas from "react-signature-canvas";
import FormattedText from "../../../../Components/FormattedText";
import { useUserData } from "./Hooks";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

export const SIGNATURE_FIELD_WIDTH = "700px";
export const SIGNATURE_FIELD_HEIGHT = "350px";

type SignatureBoxProps = {
    setSignature: (value: string) => void;
    setCanvas: (canvas: SignatureCanvas) => void;
    handleSignatureWritten?: () => void;
};

const SignatureBox = ({ setSignature, setCanvas, handleSignatureWritten }: SignatureBoxProps): ReactElement => {
    const getNameAndDate = useUserData();
    const sigCanvas = useRef<SignatureCanvas | null>(null);
    const [check1, setCheck1] = useState(false);
    const { t } = useTranslation();


    useEffect(() => {
        if (sigCanvas.current) {
            setCanvas(sigCanvas.current);
        }
    }, [sigCanvas, setCanvas]);

    const facilityName = Cookies.get("facilityName");

    return (
        <Box
            display="inline-block"
            justifyContent="center"
            alignItems="center"
            marginTop="150px"
        >
            {(facilityName === "Dentaprime London" || facilityName === "Dentaprime%20London") && (
                <Grid container>
                    <Grid container item xs={11}>
                        <Typography align="justify" variant="h6" component="h2">
                            I, the undersigned, to the best of my knowledge, have provided accurate information relating to my health, and if any changes are required, I will notify the Dental Clinic as soon as is practicable. I hereby declare that the information above is true, complete and correct. In case any of the above information is found to be false or untrue or misleading, I am aware that I may be held liable for it.
                        </Typography>
                        <Typography align="justify" variant="h6" component="h2">
                            I give my permission to have my data processed and stored electronically as well as photos to be taken for clinical purposes.
                        </Typography>
                    </Grid>
                </Grid>
            )}
            <Grid container>
                <Grid container item xs={11}>
                    <Grid item xs={2}>
                        <Checkbox
                            checked={check1}
                            disabled={false}
                            onChange={() => {
                                setCheck1(!check1);
                                if (!check1 && sigCanvas?.current) {
                                    sigCanvas.current.clear();
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={7}>
                        <Typography align="justify" variant="h6" component="h2">
                            {t("healthSign")}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Box
                bgcolor="background.paper"
                borderColor="#b2b2b4"
                m={1}
                border={1}
                borderRadius={30}
                style={{ display: check1 ? 'block' : 'none' }}
            >
                <Box
                    bgcolor="#f4f5f8"
                    m={1}
                    borderRadius={30}
                    style={{ margin: "16px" }}
                >
                    <SignatureCanvas
                        penColor="red"
                        ref={sigCanvas}
                        onEnd={() => {
                            if (sigCanvas.current) {
                                setSignature(sigCanvas.current.toDataURL());
                                if (handleSignatureWritten) {
                                    handleSignatureWritten();
                                }
                            }
                        }}
                        canvasProps={{
                            width: SIGNATURE_FIELD_WIDTH,
                            height: SIGNATURE_FIELD_HEIGHT,
                        }}
                    />
                </Box>
            </Box>
            {check1 &&
                <FormattedText text={getNameAndDate()} />
            }
        </Box>
    );
};

export default SignatureBox;
