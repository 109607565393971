import { ThemeProvider } from "@material-ui/core";
import React, { ReactElement, useState, useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/scss/main.scss";
import Axios from "axios";
import {
    SESSION_ROLE_COOKIE, CORE_ACCESS_TOKEN_COOKIE,
    PATIENT_ID_COOKIE, AGENT, FACILITY_NAME_COOKIE, BACKEND_URL
} from "./Resources/constants";
import Cookies from "js-cookie";
import RestrictedRoute from "./Components/RestrictedRoute";
import QuestionnairePage from "./Pages/QuestionnairePage";
import AccessDenied from "./Pages/AccessDenied";
import AdminPage from "./Pages/AdminPage";
import LoginPage from "./Pages/LoginPage";
import TopBar from "./Pages/TopBar";
import { Languages, UserRoles } from "./Resources/enums";
import DentaprimeTheme from "./Styles";
import CookieNotice from "react-cookie-consent";
import { DENTAPRIME_PRIMARY_COLOR } from "./Resources/constants";
import CongratulationsPage from "./Pages/CongratulationsPage";
import { DisableLanguageContext } from "./Contexts";
import Encryption from "./Pages/Encryption";
import { getIDFromToken } from "./Providers/backend";
import RecaptchaPage from "./Pages/Recaptcha";
import i18n from "./i18n/i18next";

const App = (): ReactElement => {
    const [disableLanguage, setDisableLanguage] = useState(false);
    const [isLocal, setIsLocal] = useState<boolean>();
    const [isShown, setIsShown] = useState(false);
    const recaptcha = localStorage.getItem("_grecaptcha")

    const [patientID, setPatientID]: any = useState();
    const [OrisID, setOrisID]: any = useState();
    const [location, setLocation]: any = useState();
    const history = useHistory();


    // get client's IP addres and checks it 
    useEffect(() => {
        const fetchIpAndLocalStatus = async () => {
            try {
                // Fetch IP details
                const ipResponse = await Axios.get("https://api.ipify.org?format=json");
                const ip = ipResponse.data.ip;

                // Check local status if ipDetails is not empty
                if (ip) {
                    const localStatusResponse = await Axios.get(`${BACKEND_URL}/local`, { params: { ip } });
                    setIsLocal(localStatusResponse.data.isInternalIP);
                    // Set visibility of some component
                    setIsShown(localStatusResponse.data.isInternalIP !== undefined && localStatusResponse.data.isInternalIP !== null);
                } else {
                    console.log("IP details are empty or undefined, skipping local status check.");
                }
            } catch (error) {
                console.error('Error in fetching IP or local status:', error);
            }
        };

        fetchIpAndLocalStatus();
    }, []);


    localStorage.setItem("local", isLocal + "");
    localStorage.setItem("shown", isShown + "");
    useEffect(() => {
        setOrisID(window.location.pathname.split("/")[2] + "" || "");
        setLocation(window.location.pathname.split("/")[3] + "" || "");
    }, []);

    const isValidPathSegment = (index: number): boolean => {
        return window.location.pathname.split("/").length > index;
    };

    const isValidValue = (value: string | undefined | null): value is string => {
        return value !== null && value !== undefined;
    };

    const isValidSegment = (segment: string | undefined | null): boolean => {
        return segment !== null && segment !== undefined;
    };

    const setLocalStorageItemAtIndex = (index: number, key: string, value: string | undefined | null): void => {
        if (isValidPathSegment(index) && isValidValue(value)) {
            const pathSegment = window.location.pathname.split("/")[index];
            if (isValidSegment(pathSegment)) {
                localStorage.setItem(key, value);
            }
        }
    };

    setLocalStorageItemAtIndex(2, "wfid", OrisID);
    // it should parse location to string either way is undefined ¯\_(ツ)_/¯
    setLocalStorageItemAtIndex(3, "location", String(location).toLowerCase() || "");
    useEffect(() => {
        if ((localStorage.getItem("wfid") !== undefined || localStorage.getItem("wfid") !== "") && localStorage.getItem("wfid") !== null) {
            getIDFromToken(localStorage.getItem("wfid") + "").then((response: any) => {
                setPatientID(response.data.wfid)
                Cookies.set("sid", response.data.serviceOS_id)
            }).catch((error: any) => {
                console.log(error)
            })
        }
    }, []);
    if (patientID === null || patientID === "undefined") {

        history.push("error")
    }
    if (isLocal === false && isLocal !== undefined) {
        const pathSegments = window.location.pathname.split("/");

        if (pathSegments.length > 2) {
            window.location.pathname = pathSegments.slice(0, 2).join("/");
        }

        if (isShown !== undefined) {
            const location = localStorage.getItem("location")?.toLowerCase();
            const facilityName = (location === "lon") ? "Dentaprime London" : "Dentaprime Clinic";
            Cookies.set(SESSION_ROLE_COOKIE, "2", { expires: 1 });
            Cookies.set(CORE_ACCESS_TOKEN_COOKIE, "", { expires: 1 });
            Cookies.set(PATIENT_ID_COOKIE, String(patientID));
            if (location === "Dentaprime Clinic") {
                Cookies.set(AGENT, "SDP-DACH");
            }
            Cookies.set(FACILITY_NAME_COOKIE, facilityName);
        }
    }

    const facilityNameCookie = Cookies.get(FACILITY_NAME_COOKIE);
    if (facilityNameCookie === "Dentaprime London") {
        Cookies.set("Language", Languages.EN);
    }
    /**
     * Detects the user's language and sets it in i18next.
     * Maps special cases from navigator.language to supported languages.
     * Falls back to English if the language is not supported.
     */
    const setUserLanguage = () => {
        const userLanguage = navigator.language;
        let formattedLanguage = userLanguage.length > 2
            ? userLanguage.slice(0, 2).toLowerCase()
            : userLanguage.toLowerCase();

        // Special cases for language mapping
        if (formattedLanguage === "sv") {
            formattedLanguage = "sw";
        } else if (formattedLanguage === "da") {
            formattedLanguage = "dk";
        }

        console.log(formattedLanguage);

        if (i18n.hasResourceBundle(formattedLanguage, 'translation')) {
            i18n.changeLanguage(formattedLanguage); // Set the detected language in i18next
            Cookies.set("Language", formattedLanguage.toUpperCase());
        } else {
            i18n.changeLanguage('en'); // Fallback to English if the language is not supported
        }
    };

    if (facilityNameCookie === "Dentaprime Clinic" && isLocal === false) {
        setUserLanguage()
    }

    return <div style={{ textAlign: "center" }}>
        {isShown &&
            <ThemeProvider theme={DentaprimeTheme}>
                <DisableLanguageContext.Provider value={[disableLanguage, setDisableLanguage]}>
                    <TopBar />
                    <Switch >
                        <RestrictedRoute
                            path='/filledForm'
                            levelOfSecurity={UserRoles.USER}>
                            <QuestionnairePage />
                        </RestrictedRoute>
                        <RestrictedRoute
                            path='/congratulationsPage'
                            levelOfSecurity={UserRoles.USER}>
                            <CongratulationsPage />
                        </RestrictedRoute>
                        <RestrictedRoute
                            path='/adminPanel'
                            levelOfSecurity={UserRoles.ADMIN}>
                            <AdminPage />
                        </RestrictedRoute>
                        <Route
                            path='/encrypt'
                        >
                            <Encryption />
                        </Route>
                        <Route path="/verify"
                        >
                            <RecaptchaPage />
                        </Route>
                        {isLocal ?
                            <Route path='/login'>
                                <LoginPage />
                            </Route> : <Route path='/error'>
                                <AccessDenied />
                            </Route>}
                        {isLocal ?
                            <Route>
                                <Redirect to='/login' />
                            </Route> : ((patientID !== "" &&
                                patientID !== undefined && patientID === "dW5kZWZpbmVk" &&
                                patientID !== null)) ?
                                <Route>
                                    <Redirect to='/filledForm' />
                                </Route> : <Route>
                                    <Redirect to='/error' />
                                </Route>}
                    </Switch>
                </DisableLanguageContext.Provider>
                < ToastContainer
                    className='toast-container'
                    toastClassName='toast'
                    bodyClassName='body'
                    progressClassName='progress'
                    hideProgressBar={true}
                    autoClose={5000}
                    closeButton={false}
                />
                <CookieNotice
                    location="bottom"
                    buttonText="I Agree."
                    cookieName="CookieAgreement"
                    style={{ background: DENTAPRIME_PRIMARY_COLOR, }}
                    expires={150}
                    overlay
                >
                    This website uses cookies to enhance the user experience.
                </CookieNotice>
            </ThemeProvider>}
    </div>;
};

export default App;