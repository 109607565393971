export enum CityClinics {
  // GrandMall = "Grand Mall",
  VistaPark = "Vista Park",
  DentaprimeClinic = "Dentaprime Clinic",
  Swiss = "Academy",
}

export enum Agents {
  DACH = "SDP-DACH",
  Local = "SDP-Local",
  Academy = "Academy",
  City = "Vista Park"
}

export enum FacilityNames {
  CLINIC = "Dentaprime Clinic",
  CITY = "Dentaprime City",
  F3T = "Dentaprime London",
}

export enum UserRoles {
  UNDEFINED = 0,
  USER = 1,
  ADMIN = 2,
}

//TODO: remove languages. Acquire from "get one form" WF
export enum Languages {
  EN = "EN",
  DE = "DE",
  BG = "BG",
  RU = "RU",
  FR = "FR",
  ES = "ES",
  PT = "PT",
  RO = "RO",
  IT = "IT",
  SW = "SW",
  NL = "NL",
  NO = "NO",
  DK = "DK",
  FI = "FI",
}
